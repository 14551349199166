import React from "react";
import SubpageLayout from "../components/SubpageLayout";
import { useForm } from "react-hook-form";
import { API_URL } from "../constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Mintnow = () => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors }
	} = useForm();

	const navigate = useNavigate();

	const onSubmit = async (data) => {
		try {
			// TODO: Show "message", which is "NFT minting in progress... Please wait for a few minutes."
			toast.info(
				"NFT minting in progress... Please wait for a few minutes."
			);

			const { status, message } = await axios.post(
				`${API_URL}/nfts/mint-nft`,
				data
			);

			if (!status) {
				console.log(message);
				// TODO: Show error message
				toast.error(message);
			}

			toast.success("NFT minted successfully!");

			return navigate("/success");
		} catch (error) {
			const {
				response: {
					data: { message = "Something went wrong!" }
				} = ({} = {})
			} = error;
			console.log(message, "error message");
			// TODO: Show error message from "message" variable
			toast.error(message);
		}
	};

	console.log(watch("email")); // watch input value by passing the name of it

	return (
		<SubpageLayout>
			<h1 className="m-0 mt-6">Mint Now!</h1>
			<p className="text-slategray text-center">
				Enter your email to receive your{" "}
				<br className="hidden xl:block" /> freshly minted MV Pass.
			</p>
			<form
				className="w-11/12 mx-auto flex flex-col items-center"
				onSubmit={handleSubmit(onSubmit)}
			>
				<input
					{...register("email", { required: true })}
					className={`rounded-2xl py-5 px-4 bg-slate-900 text-slategray border2 ${
						errors.email ? "border-red-600" : "border-slategray"
					} outline-none w-72 xl:w-96 my-4 `}
					placeholder="Email"
					type={"email"}
				/>

				{errors.email && (
					<p className="text-red-600 text-sm">
						This email seems incorrect, please try something else.
					</p>
				)}

				<input
					type="submit"
					className="bg-blue-100 text-white border-none rounded-lg py-4 w-80 mt-6 font-semibold mx-auto cursor-pointer"
				/>

				{/* <p className="bg-white rounded-xl py-4 px-6 text-black font-semibold">
				Scan to mint!
			</p> */}
			</form>
			<img className="mt-4" src="../images/qr-code.svg" alt="QR Code" />
			<p className="text-lg m-0 mt-4">Mint Using this QR Code</p>
			{/* <p className="text-slategray m-0 mt-3">
				Scan this QR code to start minting
			</p> */}
		</SubpageLayout>
	);
};

export default Mintnow;
