import React from "react";
import { useTimer } from "react-timer-hook";

const CountdownBox = ({ digit, boxColor, alignment }) => {
	return (
		<div
			// alignment={alignment}
			className={`font-semibold rounded-xl ${boxColor} xl:px-3 py-2 min-w-[44px] xl:min-w-[56px] text-center ${
				alignment === "justify-center" ? "mx-1" : "mr-4"
			} `}
		>
			{digit}
		</div>
	);
};

const CountDownUnit = ({ unit, digit, textColor, boxColor, alignment }) => {
	const digits = digit.toString().padStart(2, "0").split("");

	return (
		<div className="flex flex-col items-center">
			<div className="flex">
				{digits.map((digit, index) => {
					return (
						<CountdownBox
							alignment={alignment}
							key={index}
							digit={digit}
							boxColor={boxColor}
						/>
					);
				})}
			</div>
			<div className={`text-sm ${textColor} mt-4`}>{unit}</div>
		</div>
	);
};

const Countdown = ({
	timerDate,
	alignment = "justify-center",
	boxColor = "bg-darkblue",
	textColor = "text-ghostwhite"
}) => {
	const { minutes, hours, days } = useTimer({
		expiryTimestamp: timerDate
	});

	return (
		<div
			className={`flex mt-4 xl:mt-0 ${alignment} items-center text-xl xl:text-3xl xl:gap-4`}
		>
			<CountDownUnit
				boxColor={boxColor}
				textColor={textColor}
				alignment={alignment}
				unit="Day(s)"
				digit={days}
			/>
			<CountDownUnit
				boxColor={boxColor}
				textColor={textColor}
				alignment={alignment}
				unit="Hour(s)"
				digit={hours}
			/>
			<CountDownUnit
				boxColor={boxColor}
				textColor={textColor}
				alignment={alignment}
				unit="Minute(s)"
				digit={minutes}
			/>

			{/* <CountDownUnit
				boxColor={boxColor}
				textColor={textColor}
				alignment={alignment}
				unit="Second(s)"
				digit={seconds}
			/> */}
		</div>
	);
};

export default Countdown;
