import React from "react";
import { Pagination } from "swiper";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const PartnerItem = ({ partner }) => {
	return (
		<img
			className="w-[90px] h-[90px] object-contain mx-8"
			alt={partner.name}
			src={partner.image}
		/>
	);
};

const PartnersSlider = ({ partnersData }) => {
	return (
		<Swiper
			modules={[Pagination]}
			spaceBetween={50}
			slidesPerView={3}
			className="max-w-md mx-auto"
			pagination={{ clickable: true }}
			loop
		>
			{partnersData?.map((partner, idx) => {
				return (
					<SwiperSlide className="mt-4 mb-6" key={idx}>
						<PartnerItem key={partner.id} partner={partner} />
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
};

export default PartnersSlider;
