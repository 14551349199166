import React from "react";
import ReturnHomeButton from "../components/Commons/ReturnHomeButton";
import SubpageLayout from "../components/SubpageLayout";

const CheckConnection = () => {
	return (
		<SubpageLayout>
			<img
				src="../images/wifi.svg"
				alt="Mint Success"
				width={142}
				height={142}
			/>
			<p className="text-lg m-0 mt-4">Something went wrong</p>

			<p className="text-slategray text-center">
				Please check your connection and try again
			</p>
			<ReturnHomeButton />
		</SubpageLayout>
	);
};

export default CheckConnection;
