import React, { useContext } from "react";
import ReleaseDateContext from "../../context/ReleaseDateContext";
import Countdown from "../Commons/Countdown";
import MintNowButton from "../Commons/MintNowButton";

// const timerDate = new Date("2023-02-02T00:00:00").getTime();

const CountdownBox = ({ digit }) => {
	return (
		<div className="font-semibold rounded-xl bg-darkblue px-3 py-2 min-w-[56px] text-center mx-2">
			{digit}
		</div>
	);
};

const CountDownUnit = ({ unit, digit }) => {
	const digits = digit.toString().padStart(2, "0").split("");

	return (
		<div className="flex flex-col items-center">
			<div className="flex">
				{digits.map((digit, index) => {
					return <CountdownBox key={index} digit={digit} />;
				})}
			</div>
			<div className="text-sm text-ghostwhite mt-4">{unit}</div>
		</div>
	);
};

const CommunityCountDown = () => {
	const { releaseDate, isReleased } = useContext(ReleaseDateContext);

	return (
		<div className="pt-24 xl:pt-64 w-11/12 mx-auto">
			<div className="rounded-2xl bg-blue-100 flex flex-col pb-20 pt-8 px-8">
				<h1 className="text-[24px] xl:text-3xl leading-tight font-semibold text-center max-w-3xl mx-auto inline-block">
					Building a community all across the globe
				</h1>
				{isReleased ? (
					<>
						<p className="text-center">
							Join the entire media and entertainment <br />{" "}
							industry ecosystem.
						</p>
						<MintNowButton type="secondary" />
					</>
				) : (
					<Countdown timerDate={releaseDate} />
				)}
			</div>
		</div>
	);
};

export default CommunityCountDown;
