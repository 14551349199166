import { Disclosure } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";

const headerData = {
	Explore: "https://metaviva.io/",
	// Resouces: "/resouces",
	Marketplace: "https://marketplace.metaviva.io/",
	// Mint: "/mint",
	ESG: "https://metaviva.io/esg"
};

const Header = () => {
	return (
		<div className="flex items-end justify-between pt-8 w-11/12 mx-auto">
			<Link to="/">
				<img
					className="relative w-[145px] h-7 object-cover"
					alt="Metaviva Logo"
					src="../images/logo.svg"
				/>
			</Link>
			<div className="hidden xl:flex gap-[24px]">
				{Object?.keys(headerData)?.map((link) => {
					return (
						<a
							key={link}
							href={headerData[link]}
							target="_blank"
							rel="noreferrer"
							style={{ textDecoration: "none" }}
						>
							<div className="py-0 px-2 text-white hover:text-blue-200">
								{link}
							</div>
						</a>
					);
				})}
			</div>
			<Disclosure>
				{({ open }) => (
					<>
						<Disclosure.Panel className="absolute top-0 right-0 w-[100vw] h-[100vh] bg-gray-300 z-10 ">
							<div className="flex flex-col items-center justify-center h-full">
								<div className="flex flex-col gap-[24px]">
									{Object?.keys(headerData)?.map((link) => {
										return (
											<Link
												key={link}
												to={headerData[link]}
												style={{
													textDecoration: "none",
													textAlign: "center"
												}}
											>
												<div className="py-0 px-2 text-white hover:text-blue-200">
													{link}
												</div>
											</Link>
										);
									})}

									<Disclosure.Button className="mx-auto border-none rounded-full p-2 flex items-center justify-center">
										<img
											alt="Cross"
											className="w-6 h-6"
											src="../images/cross.svg"
										/>
									</Disclosure.Button>
								</div>
							</div>
						</Disclosure.Panel>
						<Disclosure.Button className="xl:hidden bg-gray-300 border-none">
							<img alt="Burger" src="../images/burger-menu.svg" />
						</Disclosure.Button>
					</>
				)}
			</Disclosure>
		</div>
	);
};

export default Header;
