import React from "react";
import ReturnHomeButton from "../components/Commons/ReturnHomeButton";
import SubpageLayout from "../components/SubpageLayout";

const MintSuccessful = () => {
	return (
		<SubpageLayout>
			<img
				src="../images/mint-success.svg"
				alt="Mint Success"
				width={124}
				height={124}
			/>
			<h1 className="m-0 mt-8">Minted and sent!</h1>
			<p className="text-slategray text-center">
				Congratulations! You just minted your MV <br /> Pass. Don&apos;t
				forget to check your email to <br /> make sure you received it.
			</p>
			<ReturnHomeButton />
		</SubpageLayout>
	);
};

export default MintSuccessful;
