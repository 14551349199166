import React from "react";
import { Disclosure } from "@headlessui/react";
// import { ChevronUpIcon } from "@heroicons/react/20/solid";

const questionsData = [
	{
		question: "How do I enjoy the MV Pass rewards?",
		answer: "Follow any one of our social media accounts to get the latest news on our community activities & enjoy our members-only perks."
	},
	{
		question: "What is your refund policy? ",
		answer: "At the moment, MV Pass is free to mint and therefore refunds are not applicable to MV Pass. If you have paid for a Metaviva NFT and did not receive it, please send us an email to investigate your case. If it is valid, we will issue you a full refund."
	},
	{
		question: "How do I cancel my subscription?",
		answer: "Feel free to make a request to us either by sending us an email or reaching us on any of our social media platforms and we will process the cancellation for you. "
	},
	{
		question: "Do you provide technical support?",
		answer: "Yes! Feel free to email us or reach us on any of our social media channels for further support."
	}
];

const Questions = () => {
	return (
		<div className="pt-8 relative ">
			<div className="divide-y-4 divide-darkslategray">
				{questionsData.map((question, index) => {
					return (
						<Disclosure key={index}>
							{({ open }) => (
								<>
									<div
										className="w-full mt-4"
										style={{
											color: "#1f2e45",
											backgroundColor: "#1f2e45",
											height: "1px"
										}}
									>
										.
									</div>
									<Disclosure.Button className="flex cursor-pointer mt-4 items-center w-full justify-between bg-transparent border-none divide-y ">
										<h3 className="text-white">
											{question.question}
										</h3>
										{!open ? (
											<img
												src="../images/plus.svg"
												alt="Plus"
											/>
										) : (
											<img
												className="w-4"
												src="../images/minus.svg"
												alt="Minus"
											/>
										)}
									</Disclosure.Button>
									<Disclosure.Panel className="pt-4 pb-2 w-full text-sm text-gray-500">
										{question.answer}
									</Disclosure.Panel>
								</>
							)}
						</Disclosure>
					);
				})}
			</div>
		</div>
	);
};

const FAQ = () => {
	return (
		<div className="pt-24 xl:pt-64 w-11/12 mx-auto">
			<div className="grid xl:grid-cols-2">
				<div>
					<h1 className="text-[24px] xl:text-2xl">
						Most recent questions
					</h1>
					<p className="text-slategray">
						These are the answers to our most commonly asked{" "}
						<br className="hidden xl:block" /> questions. If you’re
						still concerned about something. You{" "}
						<br className="hidden xl:block" /> can always contact us
						via{" "}
						<span className="font-semibold">
							contact@mataviva.io{" "}
						</span>
					</p>
				</div>
				<Questions />
			</div>
		</div>
	);
};

export default FAQ;
