import React from "react";

const footerData = {
	// Explore: "/explore",
	// Resouces: "/resouces",
	Marketplace: "https://marketplace.metaviva.io/",
	// Mint: "/mint",
	ESG: "https://metaviva.io/esg"
};

const Footer = () => {
	return (
		<div className="flex flex-col-reverse xl:flex-row justify-between pt-24 xl:pt-32 pb-16 w-11/12 mx-auto">
			<div className="text-slategray text-center xl:text-left text-sm xl:text-base mt-16 xl:mt-0">
				Copyright © 2023 Metaviva • Powered by Metanoia
			</div>
			<div className="flex flex-col xl:flex-row items-start justify-start gap-[24px]">
				{Object?.keys(footerData)?.map((link) => {
					return (
						<a
							key={link}
							href={footerData[link]}
							target="_blank"
							rel="noreferrer"
							style={{
								textDecoration: "none",
								margin: "auto"
							}}
						>
							<div className="py-0 px-2 text-center text-white hover:text-blue-200">
								{link}
							</div>
						</a>
					);
				})}
			</div>
		</div>
	);
};

export default Footer;
