import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children }) => {
	return (
		<div
			className="relative  w-full text-white font-poppins overflow-hidden"
			style={{
				background:
					"url(../images/main-bg.svg) no-repeat center center",
				backgroundSize: "cover"
			}}
		>
			<img
				src="../images/waves.svg"
				alt="Waves"
				className="absolute top-64 left-0 hidden xl:block"
			/>

			<div className="max-w-md xl:max-w-7xl leading-tight mx-auto">
				<Header /> {children} <Footer />
			</div>
		</div>
	);
};

export default Layout;
