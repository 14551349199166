import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { ReleaseDateProvider } from "./context/ReleaseDateContext";
import Mintnow from "./pages/Mintnow";
import MintSuccessful from "./pages/MintSuccessful";
import CheckConnection from "./pages/CheckConnection";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
	const releaseDate = new Date("2023-03-15T00:00:00").getTime();
	const isReleased = releaseDate < Date.now();
	// const isReleased = true;

	return (
		<ReleaseDateProvider
			value={{ releaseDate: releaseDate, isReleased: isReleased }}
		>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/mint" element={<Mintnow />} />
				<Route path="/success" element={<MintSuccessful />} />
				<Route path="/check-connection" element={<CheckConnection />} />
			</Routes>

			<ToastContainer />
		</ReleaseDateProvider>
	);
};

export default App;
