import React from "react";
import { Link } from "react-router-dom";

const ReturnHomeButton = () => {
	return (
		<Link
			to="/"
			className="bg-blue-100 cursor-pointer text-white border-none rounded-lg py-4 w-80 mt-6 font-semibold mx-auto text-center no-underline"
		>
			Return Home
		</Link>
	);
};

export default ReturnHomeButton;
