import React, { useContext } from "react";
import ReleaseDateContext from "../../context/ReleaseDateContext";
import Countdown from "../Commons/Countdown";
import MintNowButton from "../Commons/MintNowButton";

const Hero = () => {
	const { releaseDate, isReleased } = useContext(ReleaseDateContext);

	return (
		<div className="pt-16 relative w-11/12 mx-auto">
			<h1 className="text-xl xl:text-[96px]">
				Reimagine the media <br className="hidden xl:block" /> and
				entertainment <br className="hidden xl:block" /> industry
			</h1>
			<div className="flex flex-col-reverse xl:flex-row">
				<img
					className="mt-8 xl:mt-0"
					src="../images/mvpass.svg"
					alt="MV Pass"
				/>
				<div className="xl:-mt-24">
					<p className="text-slategray">
						Welcome to Metaviva, the place for YOU to reimagine{" "}
						<br className="hidden xl:block" /> the world of media
						and entertainment. Think about it –{" "}
						<br className="hidden xl:block" /> the ability to
						experience media and entertainment in a{" "}
						<br className="hidden xl:block" />
						parallel universe, where you will be given advantage in{" "}
						<br className="hidden xl:block" />
						both the physical and digital world…
					</p>

					{isReleased ? (
						<MintNowButton />
					) : (
						<>
							<h3 className="uppercase text-sm xl:text-base mt-12 xl:mt-20">
								Get Ready for the release In:
							</h3>
							<Countdown
								timerDate={releaseDate}
								alignment="justify-start"
								textColor="text-slategray"
								boxColor="bg-gray-100"
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default Hero;
