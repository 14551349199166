import React from "react";
import { useNavigate } from "react-router-dom";

const MintNowButton = ({ type = "primary" }) => {
	const navigate = useNavigate();
	return (
		<button
			onClick={() => navigate("/mint")}
			className={`${
				type === "primary"
					? "bg-blue-100 text-white"
					: "text-blue-100 bg-white"
			}  border-none rounded-lg py-4 w-full md:w-80 mt-6 font-semibold mx-auto cursor-pointer`}
		>
			Mint Now!
		</button>
	);
};

export default MintNowButton;
