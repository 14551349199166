import React from "react";

const communityBuildingCircles = [
	{
		id: 1,
		name: "Circle 1",
		image: "../images/community-building/circle-1.svg"
	},
	{
		id: 2,
		name: "Circle 2",
		image: "../images/community-building/circle-2.svg"
	},
	{
		id: 3,
		name: "Circle 3",
		image: "../images/community-building/circle-3.svg"
	},
	{
		id: 4,
		name: "Circle 4",
		image: "../images/community-building/circle-4.svg"
	}
];

const CommunityBuilding = () => {
	return (
		<div className="relative pt-16 xl:pt-64 text-center max-w-3xl w-11/12 mx-auto">
			<img
				className="w-full object-contain xl:hidden mb-8"
				src="../images/building-community-bg-mobile.svg"
				alt="Background"
			/>
			<div className="flex justify-center">
				{communityBuildingCircles.map((circle) => {
					return (
						<img
							key={circle.id}
							className="w-[40px] h-[40px] xl:w-[64px] xl:h-[64px] -ml-4 xl:-ml-8"
							alt={circle.name}
							src={circle.image}
						/>
					);
				})}
			</div>
			<h1 className="text-[24px] mt-8 xl:text-3xl leading-tight font-semibold text-center inline-block">
				Building a community all across the globe
			</h1>
			<p className="text-slategray">
				Unlock 360 Experiences in the Media and Entertainment World. MV
				Pass is Metaviva’s digital membership pass that grants users
				access to a wide range of physical, digital, and physical
				benefits in the media and entertainment world.
				<br />
				<br />
				You’ll get easy access to the metaverse, enjoy benefits from
				businesses like 33 Club, Ethica and ActivEdge and most
				importantly, exclusive access to the highlights of this industry
				through movie premiers, meet and greets and maybe even the
				opportunity to have a taste of being a filmmaker.
				<br />
				<br />
				And all it takes is one click. One click to have these endless
				opportunities and extraordinary cinematic experiences within
				your reach. One click to become a part of the vibrant Metaviva
				community. One click to unlock this new world.
				<br />
				<br />
				Now, it’s up to you… are YOU ready to{" "}
				<span className="font-semibold">become an MVP?</span>
			</p>
		</div>
	);
};

export default CommunityBuilding;
