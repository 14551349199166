import React from "react";
import Layout from "../components/Layout";
import Partners from "../components/Home/Partners";
import WhyMVPass from "../components/Home/WhyMVPass";
import CommunityCountdown from "../components/Home/CommunityCountdown";
import CommunityBuilding from "../components/Home/CommunityBuilding";
import FAQ from "../components/Home/FAQ";
import Hero from "../components/Home/Hero";

const Home = () => {
	return (
		<Layout>
			<img
				className="absolute top-[5%] -right-20 object-contain hidden xl:block"
				src="../images/hero-ribbon.svg"
				alt="Hero Ribbon"
			/>
			<img
				className="absolute top-[14%] right-[14%] object-contain xl:hidden"
				src="../images/hero-ribbon-mobile.svg"
				alt="Hero Ribbon Mobile"
			/>
			<img
				className="absolute top-[55%] left-0 w-full object-contain hidden"
				src="../images/building-community-bg.svg"
				alt="Background"
			/>
			<Hero />
			<Partners />
			<WhyMVPass />
			<CommunityBuilding />
			<FAQ />
			<CommunityCountdown />
		</Layout>
	);
};

export default Home;
