import React from "react";

const reasonsData = [
	{
		id: 1,
		title: "Win",
		description:
			"concert, movie, and other event tickets, and exclusive merchandise.",
		image: "../images/mv-pass/reason-1.svg"
	},
	{
		id: 2,
		title: "Experience",
		description:
			"behind-the-scenes adventures such as movie set visits, casting, and meet & greets.",
		image: "../images/mv-pass/reason-2.svg"
	},
	{
		id: 3,
		title: "Access",
		description:
			"our Metaverse, Limited Edition products, and special merchant discounts.",
		image: "../images/mv-pass/reason-3.svg"
	}
];

const ReasonItem = ({ reason }) => {
	return (
		<div>
			<img src={reason.image} alt={reason.title} />
			<h3>{reason.title}</h3>
			<p className="text-slategray">{reason.description}</p>
		</div>
	);
};

const WhyMVPass = () => {
	return (
		<div className="pt-44 xl:pt-64 w-11/12 mx-auto">
			<div className="grid xl:grid-cols-4 gap-12">
				<h1 className="text-[24px] xl:text-xl">
					Why get <br className="hidden xl:block" /> an MV Pass?
				</h1>

				{reasonsData.map((reason) => {
					return <ReasonItem key={reason.id} reason={reason} />;
				})}
			</div>
		</div>
	);
};

export default WhyMVPass;
