import React from "react";

const SubpageLayout = ({ children }) => {
	// check if width is mobile or desktop
	const isMobile = window.innerWidth < 395;

	return (
		<div
			className=" bg-gray-300 "
			style={{
				background: `${
					isMobile
						? "url(../images/subpages-bg-mobile.svg)"
						: "url(../images/subpages-bg.svg)"
				} no-repeat center center`,
				backgroundSize: "cover"
			}}
		>
			<div className="relative flex flex-col items-center justify-between md:w-full min-h-screen w-11/12 mx-auto text-white font-poppins ">
				<a href="https://metaviva.io/" target="_blank" rel="noreferrer">
					<img
						className="relative w-[145px] h-7 object-cover pt-12"
						alt="Metaviva Logo"
						src="../images/logo.svg"
					/>
				</a>

				<div className="relative flex flex-col items-center">
					{children}
				</div>
				<div className="text-slategray pb-8 relative text-center xl:text-left text-sm xl:text-base">
					Copyright © 2023 Metaviva • Powered by{" "}
					<a
						href="https://metanoia.country/"
						target="_blank"
						rel="noreferrer"
						className="font-bold cursor-pointer text-slategray"
						style={{
							textDecoration: "none"
						}}
					>
						Metanoia
					</a>
				</div>
			</div>
		</div>
	);
};

export default SubpageLayout;
