import React from "react";
import PartnersSlider from "./PartnersSlider";

const partnersData = [
	{
		id: 1,
		name: "33Club",
		image: "../images/brands/33club.jpeg"
	},
	{
		id: 2,
		name: "Active Edge",
		image: "../images/brands/active-edge.png"
	},
	{
		id: 3,
		name: "MM2",
		image: "../images/brands/mm2.png"
	},
	{
		id: 4,
		name: "mPlay",
		image: "../images/brands/mPlay.png"
	},
	{
		id: 5,
		name: "SDE",
		image: "../images/brands/sld.png"
	}
	// {
	// 	id: 6,
	// 	name: "Google",
	// 	image: "../images/brands/google-logo.svg"
	// }
];

const PartnerItem = ({ partner }) => {
	return (
		<img
			className="max-w-[120px] max-h-[100px] object-contain mx-8"
			alt={partner.name}
			src={partner.image}
		/>
	);
};

const Partners = () => {
	return (
		<div className="relative pt-16 xl:pt-64">
			<h3 className="uppercase font-semibold text-sm xl:text-lg text-center">
				Our partners
			</h3>
			<div className="rounded-3xl bg-gray-100 justify-center items-center py-6 mt-8 hidden xl:flex">
				{partnersData.map((partner) => {
					return <PartnerItem key={partner.id} partner={partner} />;
				})}
			</div>

			<div className="absolute xl:hidden w-screen left-1/2 top-32 -translate-x-1/2 bg-gray-100 py-2">
				<PartnersSlider partnersData={partnersData} />
			</div>
		</div>
	);
};

export default Partners;
